import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  iconButton: {
    borderRadius: 0,
  },
  iconTypography: {
    fontWeight: 500,
    fontSize: theme.typography.h5.fontSize,
  },
}))

export default styles
