import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { sortBy } from 'lodash'
import { routes } from 'Routes'
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  Hidden,
  makeStyles,
  TablePagination,
  Tooltip,
  useMediaQuery,
} from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import { useTheme } from '@material-ui/styles'
import { PlusCircle, HelpCircle as HelpCircleIcon } from 'react-feather'

import {
  ContentHeader,
  FilterButton,
  Filters,
  MenuButton,
  Page,
  Permitted,
  ButtonAction,
  DriveTour,
} from 'components'
import {
  AdvisorCard,
  ImportSuppliersDialog,
  SuppliersTable,
} from './components'

import usePagination from 'hooks/usePagination'
import useFilter from 'hooks/useFilter'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'
import constants from 'constants/index'

import * as service from 'service'
import styles from './styles'

const useStyles = makeStyles(styles)

const ClientsMain = () => {
  const filter = useFilter()
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()

  const { mixpanel } = helpers

  const [companyOptions, setCompanyOptions] = useState([])
  const [openDialog, setOpenDialog] = useState(false)
  const [openTour, setOpenTour] = useState(false)

  const [showAdvisor, setShowAdvisor] = useState(
    sessionStorage.getItem(constants.companySuppliers.ADVISOR_CARD_CLIENT) !==
      constants.companySuppliers.ADVISOR_CARD_CLOSED,
  )

  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  const { perPage, page, handleChangePage, handleChangeRowsPerPage } =
    usePagination(10)

  const { response, isLoading, refresh } = useFetch(
    service.dponet.suppliers.clientIndex,
    {
      perPage,
      page,
      ...{ ...filter.filters },
    },
    [page, perPage, filter.filters],
  )

  const formatSuppliersOptions = () => {
    const suppliers = response?.data?.companySuppliers || []
    const formattedOptions = suppliers.map((supplier) => ({
      id: supplier.supplier.id,
      name: `${supplier.supplier.name} - ${supplier.supplier.document}`,
    }))
    setCompanyOptions(formattedOptions)
  }

  useEffect(() => {
    if (response) {
      formatSuppliersOptions()
    }
  }, [response])

  const handleNew = () => {
    history.push(routes.mySuppliers.new)
  }

  const handleOpenDialog = () => {
    helpers.mixpanel.track('Meus Fornecedores', 'Importar Fornecedores', {
      action: 'importar_fornecedores-meus-fornecedores',
    })

    setOpenDialog(!openDialog)
  }

  const validationSteps = () => {
    const companySuppliers = response?.data?.companySuppliers
    if (companySuppliers === undefined) return
    if (!isLoading) {
      const steps = [
        ...constants.companySuppliers.MY_SUPPLIERS_DRIVER_STEPS_OBJECT,
      ]
      const statusSet = new Set()

      companySuppliers.forEach((supplier) => {
        const status = supplier?.status

        if (status && !statusSet.has(status)) {
          const stepObject = helpers.companySuppliers.statusDriverSteps(status)
          if (stepObject) {
            steps.push(stepObject)
            statusSet.add(status)
          }
        }
      })

      const sortedSteps = sortBy(steps, 'element')

      return sortedSteps
    }
    return []
  }

  const handleOpenTour = () => {
    setOpenTour(true)
    mixpanel.track('Meus fornecedores', 'Tour', {
      action: `tour-meus-fornecedores-fornecedores`,
    })
  }

  return (
    <Page title="Meus fornecedores">
      <Container maxWidth={false} className={classes.container}>
        <ImportSuppliersDialog open={openDialog} setOpen={setOpenDialog} />
        <ContentHeader title="Meus fornecedores">
          <Permitted tag={constants.permissions.COMPANY_SUPPLIERS.UPDATE}>
            <Tooltip title="Iniciar o tour guiado">
              <Box pr={1}>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<HelpCircleIcon size={20} />}
                  onClick={handleOpenTour}
                  fullWidth
                  id={
                    constants.companySuppliers.MY_SUPPLIERS_MAIN_DRIVER_STEP_1
                  }
                >
                  Tutorial
                </Button>
              </Box>
            </Tooltip>

            <Hidden smDown>
              <Box>
                <ButtonAction
                  variant="contained"
                  color="primary"
                  action={handleOpenDialog}
                  name="IMPORTAR FORNECEDORES"
                  id={
                    constants.companySuppliers.MY_SUPPLIERS_MAIN_DRIVER_STEP_2
                  }
                />
              </Box>
              <Box pl={1}>
                <ButtonAction
                  variant="outlined"
                  color="primary"
                  action={handleNew}
                  startIcon={<PlusCircle width="18" />}
                  name="NOVO FORNECEDOR"
                  id={
                    constants.companySuppliers.MY_SUPPLIERS_MAIN_DRIVER_STEP_3
                  }
                />
              </Box>
            </Hidden>
            <Hidden mdUp>
              <MenuButton
                anchorElement={Button}
                anchorOptions={{
                  className: classes.optionsButton,
                  variant: 'contained',
                }}
                anchorChildren="Opções"
              >
                <ButtonAction
                  color="secondary"
                  action={handleNew}
                  fullWidth
                  name="Novo Fornecedor"
                />
                <ButtonAction
                  color="secondary"
                  fullWidth
                  action={handleOpenDialog}
                  name="Importar Fornecedor"
                />
              </MenuButton>
            </Hidden>
          </Permitted>
          <Box pl={1}>
            <FilterButton setDrawerOpen={filter.setDrawerOpen} />
          </Box>
        </ContentHeader>
        {showAdvisor && (
          <AdvisorCard
            setShowAdvisor={setShowAdvisor}
            isSupplier={false}
            isSuggestion={false}
          />
        )}
        <Card>
          <Box display="flex" width="100%" mt={2}>
            <Grid container>
              <SuppliersTable
                refresh={refresh}
                isSupplier={false}
                companySuppliers={response?.data?.companySuppliers}
                isLoading={isLoading}
              />
            </Grid>
          </Box>
          <Box px={2} display="flex" justifyContent="flex-end">
            {!isLoading && response ? (
              <TablePagination
                component="div"
                count={response.data.meta.totalCount}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                page={page - 1}
                rowsPerPage={perPage}
                rowsPerPageOptions={[5, 10, 25, 100]}
                labelRowsPerPage={isDesktop ? 'Por página' : ''}
                nextIconButtonProps={{ size: 'small' }}
                backIconButtonProps={{ size: 'small' }}
              />
            ) : (
              <Box py="11px">
                <Skeleton variant="rect" width={200} height={30} />
              </Box>
            )}
          </Box>
        </Card>
        <Filters filter={filter}>
          <input textfieldinput="true" label="Identificador" name="id" />
          <select
            autocompleteselectinput="true"
            optionvalues={companyOptions}
            label="Fornecedor"
            name="supplier_id"
          />
          <select textfieldinput="true" label="Status" name="status">
            <option value=""></option>
            {constants.companySuppliers.STATUSES_LABEL.map((status) => (
              <option key={status.key} value={status.key}>
                {status.label}
              </option>
            ))}
          </select>
        </Filters>
      </Container>
      <DriveTour
        stepsMatrix={validationSteps()}
        open={openTour}
        setOpen={setOpenTour}
      />
    </Page>
  )
}

export default ClientsMain
