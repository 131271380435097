import React, { Fragment, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import {
  File as FileIcon,
  Upload as UploadIcon,
  X as XIcon,
} from 'react-feather'
import { isNil, size, isEmpty } from 'lodash'
import PropTypes from 'prop-types'
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core'

import helpers from 'helpers'

import styles from './styles'

import theme from 'theme'
import constants from 'constants/index'

const FileUploadInput = ({
  accept = constants.validations.ALL_FILE_TYPES,
  attachmentsBgcolor = theme.palette.custom.light,
  backgroundColor,
  controllerBar = false,
  controlName,
  EndIcon = FileIcon,
  multiple = false,
  StartIcon = UploadIcon,
  subCaption = '',
  title,
  width,
}) => {
  const [isDragging, setIsDragging] = useState(false)

  const { isClear } = helpers.validates.lodash
  const { control, watch, setValue, errors } = useFormContext()

  const inputRef = useRef(null)
  const selectedFile = watch(controlName)

  const colorInput = () => {
    if (errors?.[controlName])
      return isDragging ? theme.palette.error.dark : theme.palette.error.main
    return isDragging ? theme.palette.primary.hover : theme.palette.custom.dark
  }

  const useStyles = styles({
    attachmentsBgcolor,
    backgroundColor,
    color: colorInput(),
    isDragging,
    width,
  })
  const classes = useStyles()

  const isMultiple = size(selectedFile) > 1

  const handleClickFormControl = () => inputRef?.current?.click()

  const updateFileList = (files, isDrop = false) => {
    if (isClear(files)) return null

    const currentFiles = selectedFile ? Array.from(selectedFile) : []
    let updatedFiles

    if (multiple && controllerBar) {
      updatedFiles = [...currentFiles, ...Array.from(files)]
    } else if (multiple) {
      updatedFiles = files
    } else {
      updatedFiles = files[0]
    }

    if (isDrop) setValue(controlName, updatedFiles)
    else return updatedFiles
  }

  const handleFileChange = (event) => {
    const files = event.target.files
    const updatedFiles = updateFileList(files)

    return updatedFiles
  }

  const handleDragOver = (event) => {
    event.preventDefault()
    setIsDragging(true)
  }

  const handleDragLeave = () => setIsDragging(false)

  const handleDrop = (event) => {
    event.preventDefault()
    handleDragLeave()
    let files = event?.dataTransfer?.files

    updateFileList(files, true)
  }

  const handleRemoveFile = (event, index) => {
    event.stopPropagation()
    let currentFiles = Array.from(selectedFile) || []
    currentFiles.splice(index, 1)

    const files = helpers.validates.file.arrayToFileList(currentFiles)

    setValue(controlName, size(files) > 0 ? files : null)
  }

  const handleDownloadFile = (file) => {
    const url = URL.createObjectURL(file)
    const link = document.createElement('a')
    link.href = url
    link.download = file.name
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    URL.revokeObjectURL(url)
  }

  return (
    <Box>
      <Controller
        as={
          <FormControl
            className={classes.formControl}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={handleClickFormControl}
          >
            <input
              ref={inputRef}
              type="file"
              accept={accept || '*/*'}
              aria-label="Selecionar arquivo"
              className={classes.input}
              multiple={multiple}
            />

            {isNil(selectedFile) ? (
              <Fragment>
                <StartIcon />
                <Typography component="span" className={classes.title}>
                  {title}
                </Typography>
                {accept && (
                  <Box display="flex" flexDirection="column">
                    <Typography variant="caption">
                      Arquivos suportados:&nbsp;
                      {helpers.formatters.file.allowedTypes(accept)}
                    </Typography>
                    {subCaption && (
                      <Typography variant="caption">{subCaption}</Typography>
                    )}
                  </Box>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Box
                  display="flex"
                  alignItems="center"
                  maxWidth="90%"
                  gridGap={theme.spacing(2)}
                >
                  <Box>
                    <EndIcon size={20} />
                  </Box>
                  <Typography noWrap>
                    {!isMultiple
                      ? multiple
                        ? selectedFile[0]?.name
                        : selectedFile?.name
                      : `${size(selectedFile)} arquivos selecionados`}
                  </Typography>
                  {!isMultiple && (
                    <Box>
                      <Typography variant="caption" noWrap width="fit-content">
                        {helpers.formatters.file.size(
                          multiple ? selectedFile[0]?.size : selectedFile?.size,
                        )}
                      </Typography>
                    </Box>
                  )}
                </Box>
                <Typography variant="caption">
                  Clique ou arraste para adicionar um novo arquivo
                </Typography>
              </Fragment>
            )}
          </FormControl>
        }
        control={control}
        name={controlName}
        onChange={([event]) => handleFileChange(event)}
        mode="onChange"
      />
      {!!errors?.[controlName] && (
        <FormHelperText error={!!errors?.[controlName]}>
          {<>{String(errors?.[controlName]?.message)}</>}
        </FormHelperText>
      )}
      {controllerBar && multiple && !isEmpty(selectedFile) && (
        <Box className={classes.controlBar}>
          <PerfectScrollbar
            options={{ wheelPropagation: true, useBothWheelAxes: true }}
          >
            <Box
              display="flex"
              alignItems="center"
              gridGap={theme.spacing(2)}
              pb={2}
            >
              {Array.from(selectedFile)?.map((file, index) => (
                <Box
                  key={index}
                  className={classes.attachmentItem}
                  onClick={() => handleDownloadFile(file)}
                >
                  <Tooltip
                    title="Clique aqui para baixar o arquivo"
                    placement="top"
                    arrow
                  >
                    <Box display="flex" gridGap={theme.spacing(1)}>
                      <EndIcon size={18} />
                      <Typography noWrap>{file?.name}</Typography>
                    </Box>
                  </Tooltip>
                  <IconButton
                    size="small"
                    onClick={(event) => handleRemoveFile(event, index)}
                  >
                    <XIcon size={14} />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </PerfectScrollbar>
        </Box>
      )}
    </Box>
  )
}

FileUploadInput.propTypes = {
  accept: PropTypes.string.isRequired,
  attachmentsBgcolor: PropTypes.string,
  backgroundColor: PropTypes.string,
  controllerBar: PropTypes.bool,
  controlName: PropTypes.string.isRequired,
  EndIcon: PropTypes.elementType,
  multiple: PropTypes.bool,
  StartIcon: PropTypes.elementType,
  subCaption: PropTypes.string,
  title: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
}

export default FileUploadInput
