import React from 'react'
import { useHistory } from 'react-router-dom'
import { reverse } from 'named-urls'
import {
  Calendar as CalendarIcon,
  File as FileIcon,
  AlertTriangle as AlertFragilityIcon,
} from 'react-feather'
import {
  Card,
  CardHeader,
  CardContent,
  Box,
  Typography,
  Grid,
  makeStyles,
  Divider,
  Tooltip,
} from '@material-ui/core'

import { NoData, LoadingFeedback } from 'components'
import { DataProcessCardMenuItems, CardColumn, CardRow } from './components'

import usePermission from 'hooks/usePermissions'
import useFetch from 'hooks/useFetch'

import helpers from 'helpers'

import { routes } from 'Routes'
import * as service from 'service'
import constants from 'constants/index'
import styles from './styles'
import theme from 'theme'

const useStyles = makeStyles(styles)

const DataProcessCard = ({
  dataProcesses,
  isLoading,
  origin,
  onEvent = () => {},
  showDetails = true,
  setIsLoading = () => {},
  defaultStatus,
  ...rest
}) => {
  const history = useHistory()
  const classes = useStyles()
  const { permitted } = usePermission()

  const { response } = useFetch(service.dponet.departments.get, {
    perPage: 1000,
  })

  const handleGoView = (dataProcessId) => {
    if (
      origin === constants.dataProcess.CARD_ORIGIN_DATA_PROCESS_SUGGESTION ||
      origin === constants.dataProcess.CARD_ORIGIN_DISCARDED
    ) {
      return history.push(
        reverse(
          `/data-processes/show/${dataProcessId}?showTemplate=true&origin=${origin}`,
        ),
      )
    }
    if (origin === constants.dataProcess.CARD_ORIGIN_SUGGESTED_CHANGES) {
      return history.push(
        reverse(routes.dataProcess.viewSuggestions, {
          dataProcessId: dataProcessId,
        }),
      )
    }

    history.push(
      reverse(routes.dataProcess.view, { dataProcessId: dataProcessId }),
    )
  }

  const permitRedirectToShow = () => {
    return showDetails && permitted('list_data_processes')
  }

  const tourId =
    defaultStatus === constants.dataProcess.DISCARDED
      ? constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEP_5
      : constants.dataProcess.DATA_PROCESS_SUGGESTED_DRIVER_STEP_4

  return (
    <>
      {isLoading ? (
        <LoadingFeedback open={isLoading} />
      ) : (
        dataProcesses?.map((dataProcess) => (
          <Grid item {...rest} key={dataProcess.id} id={tourId}>
            <Card {...rest} className={classes.card}>
              <CardHeader
                className={classes.cardHeader}
                classes={{
                  content: classes.cardHeaderContent,
                }}
                action={
                  showDetails && (
                    <Box mt={1}>
                      <DataProcessCardMenuItems
                        dataProcess={dataProcess}
                        status={dataProcess.statusId}
                        onEvent={onEvent}
                        origin={origin}
                        departments={response?.data?.departments}
                        setIsLoading={setIsLoading}
                      />
                    </Box>
                  )
                }
                title={
                  <Tooltip title={dataProcess.name} placement="bottom-start">
                    <Typography noWrap variant="h5">
                      {`#${dataProcess.id} - ${dataProcess.name}`}
                    </Typography>
                  </Tooltip>
                }
              />
              <Divider />
              <CardContent
                className={
                  permitRedirectToShow()
                    ? classes.cardLinkContent
                    : classes.cardContent
                }
                onClick={() =>
                  permitRedirectToShow() && handleGoView(dataProcess.id)
                }
              >
                <CardRow minHeight="5em" className={classes.statusSection}>
                  <Box
                    width="50%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                    className={classes.statusSection}
                  >
                    <Typography variant="subtitle2">
                      {dataProcess?.department?.name?.toUpperCase() || '-'}
                    </Typography>
                    <Typography color="secondary">DEPARTAMENTO</Typography>
                  </Box>
                  <Box
                    id={helpers.dataProcess.driverJs.statusId(
                      dataProcess?.statusId,
                    )}
                    width="50%"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="space-evenly"
                    className={helpers.dataProcess.statusColor(
                      defaultStatus || dataProcess.statusId,
                      classes,
                    )}
                  >
                    <Typography
                      className={
                        defaultStatus === constants.dataProcess.DISCARDED
                          ? helpers.dataProcess.textStatusColor(
                              defaultStatus,
                              classes,
                            )
                          : classes.textWhite
                      }
                    >
                      {helpers.dataProcess.status(
                        defaultStatus || dataProcess.statusId,
                      )}
                    </Typography>
                    <Typography
                      className={helpers.dataProcess.textStatusColor(
                        defaultStatus || dataProcess.statusId,
                        classes,
                      )}
                    >
                      STATUS
                    </Typography>
                  </Box>
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={
                      <AlertFragilityIcon
                        color={theme.palette.secondary.main}
                      />
                    }
                    className={helpers.dataProcess.fragilityColor(
                      dataProcess?.adoptedFragilityId,
                      classes,
                    )}
                    title="Risco"
                    subtitle={helpers.dataProcess.fragilityLabel(
                      dataProcess?.adoptedFragilityId,
                    )}
                  />
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={<CalendarIcon color={theme.palette.secondary.main} />}
                    title="Criado em"
                    subtitle={helpers.formatters.date(dataProcess?.createdAt)}
                  />
                  <CardColumn
                    icon={<CalendarIcon color={theme.palette.secondary.main} />}
                    title="Última atualização"
                    subtitle={`feita ${helpers.formatters.dateFromNow(
                      dataProcess?.updatedAt,
                    )}`}
                  />
                </CardRow>
                <Divider />
                <CardRow>
                  <CardColumn
                    icon={<FileIcon color={theme.palette.secondary.main} />}
                    title="Por que o dado é tratado?"
                    subtitle={dataProcess?.finality || '-'}
                    noWrap
                    width="100%"
                  />
                </CardRow>
              </CardContent>
            </Card>
          </Grid>
        ))
      )}
      <Grid item sm={12}>
        {dataProcesses && !dataProcesses.length && (
          <NoData message="Nenhum dado foi encontrado." />
        )}
      </Grid>
    </>
  )
}

export default DataProcessCard
