import React, { useState } from 'react'
import { FormContext, useForm } from 'react-hook-form'
import PropTypes from 'prop-types'

import { TextBox } from 'components'

import helpers from 'helpers'

import useSnackbar from 'hooks/useSnackbar'

import schema from './schema'
import * as service from 'service'

const MessageForm = ({ complaint, complaintId, refresh, setLoading }) => {
  const [requestError, setRequestError] = useState('')

  const snackbar = useSnackbar()

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      content: '',
      attachments: [],
      complaintStatus: complaint?.status || '',
    },
  })

  const { handleSubmit, reset } = formMethods

  const buttonItems = helpers.complaint.statusButtonItems(complaint?.status)

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      await service.dponet.complaintMessage.create({
        complaintId,
        ...data,
      })
      refresh()
      setRequestError('')
      reset()
      snackbar.open({
        message: 'Mensagem enviada com sucesso!',
        variant: 'success',
      })
    } catch (error) {
      setRequestError(error)
      console.error(error)
      snackbar.open({
        message:
          helpers.formatters.errorMessage(error?.response?.data?.error) ||
          'Ocorreu um erro tentar enviar a mensagem!',
        variant: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <FormContext {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit)} id="complaint-form">
        <TextBox
          buttonItems={buttonItems}
          requestError={requestError}
          controlName="complaintStatus"
          form="complaint-form"
        />
      </form>
    </FormContext>
  )
}

MessageForm.propTypes = {
  complaint: PropTypes.object,
  complaintId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  refresh: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
}

export default MessageForm
