import React, { useEffect, useState } from 'react'
import { Controller, FormContext, useForm } from 'react-hook-form'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import { isEmpty, size } from 'lodash'
import { Box, TextField, Typography } from '@material-ui/core'
import humps from 'humps'

import { Card, FileUploadInput, Label, LoadingFeedback } from 'components'

import helpers from 'helpers'

import useFetch from 'hooks/useFetch'
import useSnackbar from 'hooks/useSnackbar'
import useIncidentManegement from 'hooks/useIncidentManegement'

import * as service from 'service'
import useStyles from './styles'
import schema from './schema'
import theme from 'theme'
import constants from 'constants/index'

const DetectionStep = ({ query }) => {
  const [loading, setLoading] = useState(false)

  const classes = useStyles()
  const snackbar = useSnackbar()
  const {
    incident,
    setIncident,
    handleNext,
    wantsToAdvanceStep,
    resetAdvanceStep,
  } = useIncidentManegement()

  const formMethods = useForm({
    validationSchema: schema,
    defaultValues: {
      discoveryDescription: incident?.discoveryDescription || '',
      detectionAttachments: incident?.detectionAttachmentsList,
    },
  })

  const { control, errors, getValues, triggerValidation } = formMethods

  const { response, isLoading } = useFetch(
    service.dponet.complaint.get,
    {
      complaintId: query?.complaintId,
      minimal: true,
    },
    [query?.complaintId],
    !!query?.complaintId,
  )

  const complaint = response?.data?.complaint

  const onSubmit = async () => {
    setLoading(true)

    try {
      const validForm = await triggerValidation()
      const data = getValues()

      if (!validForm) return

      if (isEmpty(incident)) {
        await createIncident(data)
      } else {
        await updateIncident(data)
      }
      resetAdvanceStep()
      handleNext()
    } catch (error) {
      console.error(error)
      snackbar.open({
        message: helpers.formatters.errorMessage(error.response.data.error),
        variant: 'error',
      })
    } finally {
      setLoading(false)
      resetAdvanceStep()
    }
  }

  const createIncident = async (data) => {
    const response = await service.dponet.incidents.create({
      ...data,
      ...(!!query?.complaintId &&
        !!query?.convertedComplaintIncident && {
          incident: {
            complaintId: query?.complaintId,
            convertedComplaintIncident: JSON.parse(
              query?.convertedComplaintIncident.toLowerCase(),
            ),
          },
        }),
    })

    const incidentResponse = response?.data?.incident

    setIncident(humps.camelizeKeys(incidentResponse))
  }

  const updateIncident = async (data) => {
    const newFilesArray = helpers.validates.file.filterExistingFiles(
      data?.detectionAttachments,
      incident.detectionAttachmentsList,
    )

    const newFiles = helpers.validates.file.arrayToFileList(newFilesArray)
    const hasNewFiles = size(newFiles) > 0

    const filesToDeleteArray = helpers.validates.file.filterExistingFiles(
      incident.detectionAttachmentsList,
      data?.detectionAttachments,
    )

    const filesToDelete = helpers.validates.file.findFileIdByName(
      filesToDeleteArray,
      incident.detectionAttachments,
    )
    const hasFilesToDelete = size(filesToDelete) > 0

    const response = await service.dponet.incidents.putDetectionForm({
      incidentId: incident.id,
      discoveryDescription: data.discoveryDescription,
      detectionForm: true,
      ...(hasNewFiles ? { detectionAttachments: newFiles } : {}),
      ...(hasFilesToDelete ? { filesToDelete } : {}),
    })

    const incidentResponse = response?.data?.incident

    setIncident(humps.camelizeKeys(incidentResponse))
  }

  useEffect(() => {
    if (wantsToAdvanceStep) {
      onSubmit()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToAdvanceStep])

  return (
    <Box>
      <LoadingFeedback open={loading || isLoading} />
      {complaint?.description && (
        <Card
          title="Relato da denúncia"
          childrenStyle={{
            paddingLeft: theme.spacing(2),
            paddingBlock: theme.spacing(2),
          }}
        >
          <PerfectScrollbar
            options={{ wheelPropagation: false, useBothWheelAxes: true }}
            className={classes.complaintDescriptionContainer}
          >
            <Typography className={classes.complaintDescription}>
              {complaint?.description}
            </Typography>
          </PerfectScrollbar>
        </Card>
      )}
      <FormContext {...formMethods}>
        <Card title="Detecção do Incidente">
          <Box display="flex" flexDirection="column" gridGap={theme.spacing(2)}>
            <Label
              title="Descreva como ficou sabendo a respeito do incidente:"
              paddingBoxTitle={0}
              xs={12}
              item
            >
              <Controller
                as={
                  <TextField
                    fullWidth
                    variant="outlined"
                    multiline
                    minRows={4}
                    error={!!errors.discoveryDescription}
                    helperText={errors?.discoveryDescription?.message}
                  />
                }
                control={control}
                name="discoveryDescription"
              />
            </Label>
            <Label title="Anexo" paddingBoxTitle={0} xs={12} item>
              <FileUploadInput
                controlName="detectionAttachments"
                title="Arraste e solte ou selecione o documento a ser anexado"
                accept={constants.validations.ALL_FILE_TYPES}
                multiple
                controllerBar
              />
            </Label>
          </Box>
        </Card>
      </FormContext>
    </Box>
  )
}

DetectionStep.propTypes = {
  query: PropTypes.object,
}

export default DetectionStep
