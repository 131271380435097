import React, { useState } from 'react'
import { Box, Grid, InputLabel } from '@material-ui/core'
import { FormContext, useForm } from 'react-hook-form'
import { isEmpty } from 'lodash'

import { Container, Page } from 'components'
import {
  ConsentReportPdf,
  Header,
  ReportSelection,
  ReportVersionExistsDialog,
  ReportVersionTable,
} from './components'

import combinedSchema from 'views/Consent/components/DialogReportVersion/schema'

import useSnackbar from 'hooks/useSnackbar'
import constants from 'constants/index'
import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'

const ConsentReport = () => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [exportingPdf, setExportingPdf] = useState(false)
  const [refresher, setRefresher] = useState(false)
  const [reportVersionExistsOpen, setReportVersionExistsOpen] = useState(false)
  const [currentReportVersion, setCurrentReportVersion] = useState()
  const [reportSelected, setReportSelected] = useState('')
  const [consentForms, setConsentForms] = useState([])
  const [loadingConsentStats, setLoadingConsentStats] = useState(false)

  const { consent } = constants.reports.DESCRIPTION
  const { RECEIPT_COMPLETE } = constants.reports.TYPES_CONSENTS

  const getConsentForms = async () => {
    setLoadingConsentStats(true)
    try {
      const response = await service.dponet.consent.getStats({})

      setConsentForms(response?.data?.consentForms)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoadingConsentStats(false)
  }

  const formMethods = useForm({
    validationSchema: combinedSchema(reportSelected),
  })

  const { control, watch, errors, triggerValidation, clearError } = formMethods

  const generatePdfLiaReport = async () => {
    if (reportSelected !== RECEIPT_COMPLETE) await triggerValidation()

    if (!isEmpty(errors)) return

    if (reportSelected === RECEIPT_COMPLETE) await getConsentForms()

    if (!loadingConsentStats) {
      if (!!currentReportVersion?.id) {
        return setReportVersionExistsOpen(true)
      }
      setExportingPdf(true)
    }
  }

  const handleRefresh = () => setRefresher(!refresher)
  const handleExportingPdf = () => setExportingPdf(true)

  return (
    <Page className={classes.root} title="Relatório de Consentimentos">
      <Container maxWidth={false} spacingXl={15} spacingLg={10} spacingMd={5}>
        <Box py={5}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Header
                type="consent"
                reportTour="consent"
                isLoading={loadingConsentStats}
                buttonAction={generatePdfLiaReport}
                disabled={!!exportingPdf}
                title={consent.title}
                description={consent.content}
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={classes.label}>
                Escolha a versão do relatório:
              </InputLabel>
              <FormContext {...formMethods}>
                <ReportSelection
                  reportSelected={reportSelected}
                  setReportSelected={setReportSelected}
                  consentForms={consentForms}
                  setConsentForms={setConsentForms}
                  exportingPdf={exportingPdf}
                  setExportingPdf={setExportingPdf}
                />
              </FormContext>
            </Grid>
            <Grid item xs={12}>
              <ReportVersionTable
                currentReportVersion={currentReportVersion}
                kind={constants.reportVersions.KIND.CONSENT}
                refresher={refresher}
                setCurrentReportVersion={setCurrentReportVersion}
              />
            </Grid>
          </Grid>
        </Box>
        <ConsentReportPdf
          exportingPdf={exportingPdf}
          setExportingPdf={setExportingPdf}
          consentForms={consentForms}
          shouldCreateVersion={!currentReportVersion?.id}
          refresh={handleRefresh}
          reportSelected={reportSelected}
        />
        <ReportVersionExistsDialog
          currentReportVersion={currentReportVersion}
          exportAction={handleExportingPdf}
          open={reportVersionExistsOpen}
          setCurrentReportVersion={setCurrentReportVersion}
          setOpen={setReportVersionExistsOpen}
        />
      </Container>
    </Page>
  )
}
export default ConsentReport
