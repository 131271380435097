import { getBaseURL } from 'service/env'
import * as service from 'service'
import axios from 'axios'

const baseURL = getBaseURL('dponet')

const create = async ({ complaintId = '', ...data }) => {
  var formData = new FormData()

  formData.append('complaint_message[content]', data.content)
  formData.append('complaint_message[complaint_status]', data.complaintStatus)

  Array.from(data.attachments).map((attachment) => {
    return formData.append(`complaint_message[attachments][]`, attachment)
  })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/complaints/${complaintId}/complaint_messages`,
    data: formData,
    ...config,
  })
}

export default { create }
