import React, { useState } from 'react'
import { useForm, FormContext } from 'react-hook-form'
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { isEmpty } from 'lodash'

import { ConsentReportPdf, ReportSelection } from 'views/Reports/components'
import { LoadingFeedback } from 'components'

import useSnackbar from 'hooks/useSnackbar'
import constants from 'constants/index'
import combinedSchema from './schema'
import * as service from 'service'
import helpers from 'helpers'

import useStyles from './styles'

const DialogReportVersion = ({ open, setOpen }) => {
  const classes = useStyles()
  const snackbar = useSnackbar()

  const [loadingConsentStats, setLoadingConsentStats] = useState(false)
  const [reportSelected, setReportSelected] = useState('')
  const [exportingPdf, setExportingPdf] = useState(false)
  const [consentForms, setConsentForms] = useState([])
  const [refresher, setRefresher] = useState(false)

  const { RECEIPT_COMPLETE } = constants.reports.TYPES_CONSENTS

  const formMethods = useForm({
    validationSchema: combinedSchema(reportSelected),
  })
  const { control, errors, watch, clearError, triggerValidation } = formMethods

  const getConsentForms = async () => {
    setLoadingConsentStats(true)
    try {
      const response = await service.dponet.consent.getStats({})
      setConsentForms(response?.data?.consentForms)
    } catch (error) {
      snackbar.open({
        message: helpers.formatters.errorMessage(error?.response?.data?.error),
        variant: 'error',
      })
    }
    setLoadingConsentStats(false)
  }

  const generatePdfLiaReport = async () => {
    if (reportSelected !== RECEIPT_COMPLETE) await triggerValidation()

    if (!isEmpty(errors)) return

    if (reportSelected === RECEIPT_COMPLETE) await getConsentForms()

    if (!loadingConsentStats) {
      setExportingPdf(true)
    }
  }

  const handleSetOpenDialog = () => setOpen(false)

  return (
    <Dialog open={open} onClose={handleSetOpenDialog} fullWidth>
      <LoadingFeedback open={loadingConsentStats} />
      <DialogTitle>
        <Box>
          <Typography variant="h5">Escolha a versão do relatório:</Typography>
        </Box>
      </DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <Grid container spacing={2}>
          <FormContext {...formMethods}>
            <ReportSelection
              reportSelected={reportSelected}
              setReportSelected={setReportSelected}
              setConsentForms={setConsentForms}
              consentForms={consentForms}
              exportingPdf={exportingPdf}
              setExportingPdf={setExportingPdf}
              setLoadingConsentStats={setLoadingConsentStats}
            />
          </FormContext>
        </Grid>
      </DialogContent>
      <DialogActions p={2} className={classes.buttonBox}>
        <Box pl={2} pr={1}>
          <Button type="button" variant="text" onClick={handleSetOpenDialog}>
            Cancelar
          </Button>
        </Box>
        <Button
          variant="contained"
          onClick={generatePdfLiaReport}
          color="primary"
        >
          Baixar Recibo
        </Button>
      </DialogActions>

      <ConsentReportPdf
        exportingPdf={exportingPdf}
        consentForms={consentForms}
        refresh={() => setRefresher(!refresher)}
        setExportingPdf={setExportingPdf}
        reportSelected={reportSelected}
      />
    </Dialog>
  )
}

export default DialogReportVersion
