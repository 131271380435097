import api from 'service/api'
import axios from 'axios'
import * as service from 'service'

import { getBaseURL } from 'service/env'
import { isUndefined } from 'lodash'

const dponetAPI = api.create('dponet')
const baseURL = getBaseURL('dponet')

const get = async ({ incidentId = '', ...params }) => {
  return await dponetAPI.get(`/incidents/${incidentId}`, { params })
}

const create = async ({ ...data }) => {
  const formData = new FormData()

  formData.append('incident[discovery_description]', data.discoveryDescription)

  if (
    !!data?.incident?.convertedComplaintIncident &&
    !!data?.incident?.complaintId
  ) {
    formData.append(
      'incident[converted_complaint_incident]',
      data?.incident?.convertedComplaintIncident,
    )
    formData.append('incident[complaint_id]', data?.incident?.complaintId)
  }

  data?.detectionAttachments &&
    Array.from(data?.detectionAttachments).map((detectionAttachment) => {
      return formData.append(
        `incident[detection_attachments][]`,
        detectionAttachment,
      )
    })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'POST',
    url: `${baseURL}/incidents`,
    data: formData,
    ...config,
  })
}

const putDetectionForm = async ({ incidentId = '', ...data }) => {
  const formData = new FormData()

  formData.append('incident[discovery_description]', data.discoveryDescription)
  formData.append('incident[detection_form]', data.detectionForm)

  data?.filesToDelete &&
    Array.from(data.filesToDelete).map((fileToDelete) => {
      return formData.append(`incident[files_to_delete][]`, fileToDelete)
    })

  data?.detectionAttachments &&
    Array.from(data?.detectionAttachments).map((detectionAttachment) => {
      return formData.append(
        `incident[detection_attachments][]`,
        detectionAttachment,
      )
    })

  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': 'attachment',
      Authorization: service.dponet.auth.getToken(),
    },
  }

  return await axios({
    method: 'PUT',
    url: `${baseURL}/incidents/${incidentId}`,
    data: formData,
    ...config,
  })
}

const put = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(`/incidents/${incidentId}`, data)
}

const destroy = async ({ incidentId }) => {
  return await dponetAPI.delete(`/incidents/${incidentId}`)
}

const linkProcess = async ({ incidentId, dataProcessId }) => {
  return await dponetAPI.post(
    `/incidents/${incidentId}/link_data_process/${dataProcessId}`,
  )
}

const unlinkProcess = async ({ incidentId, dataProcessId }) => {
  return await dponetAPI.delete(
    `/incidents/${incidentId}/unlink_data_process/${dataProcessId}`,
  )
}

const logs = async ({ incidentId }) => {
  return await dponetAPI.get(`/incidents/${incidentId}/logs`)
}

const options = async ({ ...params }) => {
  return await dponetAPI.get('/incident_options', { params })
}

const addStepRisk = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(`/risk_consequence/${incidentId}`, data)
}

const addStepSecurity = async ({ incidentId = '', ...data }) => {
  return await dponetAPI.put(
    `/technical_administrative_security/${incidentId}`,
    data,
  )
}

const createMinimal = async (data) => {
  return await dponetAPI.post(`/incidents/minimal`, data)
}

const concludeStep = async ({ incidentId = '', step = '' }) => {
  return await dponetAPI.put(`/incidents/${incidentId}/steps/${step}/conclude`)
}

const incidents = {
  addStepRisk,
  addStepSecurity,
  concludeStep,
  create,
  createMinimal,
  destroy,
  get,
  linkProcess,
  logs,
  options,
  put,
  putDetectionForm,
  unlinkProcess,
}

export default incidents
