import api from 'service/api'

const dponetAPI = api.create('dponet')

const get = async ({ complaintId = '', ...params }) => {
  return await dponetAPI.get(`/complaints/${complaintId}`, {
    params,
  })
}

const registerResponsible = async ({ complaintId = '', ...params }) => {
  return await dponetAPI.put(`/complaints/${complaintId}/responsible`, params)
}

export default { get, registerResponsible }
