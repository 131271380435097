import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'
import { Alert } from '@material-ui/lab'
import { Box, Grid, Typography } from '@material-ui/core'

import { Page, Container, LoadingFeedback, ContentHeader } from 'components'
import {
  QuestionnaireCard,
  AdequacyModal,
  ComplianceLevelBox,
  LevelAlert,
} from './components'

import useFetch from 'hooks/useFetch'
import useFilter from 'hooks/useFilter'
import useAuth from 'hooks/useAuth'

import helpers from 'helpers'
import * as service from 'service'
import constants from 'constants/index'

const QuestionnairesMain = () => {
  const filter = useFilter()
  const { company } = useAuth()

  const { mixpanel } = helpers

  const [openModal, setOpenModal] = useState(false)
  const referrer = document.referrer
  const params = new URL(referrer || 'https://app.dponet.com.br')

  const { response, isLoading, refresh } = useFetch(
    service.dponet.questionnaires.get,
    {
      perPage: 9999,
      hideQuestions: true,
      ...{ ...filter.filters },
    },
    [filter.filters],
  )

  const {
    response: responseQuestionnaireLevel,
    isLoading: isLoadingQuestionnaireLevel,
    refresh: refreshQuestionnaireLevel,
  } = useFetch(service.dponet.companies.questionnaireComplianceLevel, {
    companyId: company.id,
  })

  const questionnaireLevelInfo = responseQuestionnaireLevel?.data?.company

  const fundamentalQuestionnaire = response?.data?.questionnaires?.find(
    (questionnaire) =>
      questionnaire.kind === constants.questionnaire.FUNDAMENTAL_REQUIREMENTS,
  )

  const nonCompliance =
    Number(fundamentalQuestionnaire?.complianceGap ?? 100) < 100

  const zeroQuestionnaires = isEmpty(response?.data?.questionnaires)

  const handleClose = () => {
    mixpanel.track(
      'Jornada de Fornecedores',
      'Modal: Adequação pendências de medidas de controle importantes',
      {
        action: 'Fechar-Adequação',
      },
    )
    setOpenModal(false)
  }

  useEffect(() => {
    const searchPath = params.pathname
    if (searchPath === '/supplier-step' && nonCompliance > 0) {
      setOpenModal(true)
    }
    // eslint-disable-next-line
  }, [nonCompliance])

  return (
    <Page title="Todos os questionários">
      <LoadingFeedback open={isLoading || isLoadingQuestionnaireLevel} />
      <Container maxWidth={false}>
        <ContentHeader title="Questionários" />

        {zeroQuestionnaires && !isLoading && (
          <Alert variant="filled" severity="info" color="warning">
            <Typography>
              Você não tem permissões para visualizar questionários. Entre em
              contato com o responsável ou co-responsável pela empresa e
              solicite a sua permissão.
            </Typography>
          </Alert>
        )}
        {!isLoading && !zeroQuestionnaires && (
          <>
            {company.kind !== constants.preRegistrations.SUPPLIER_KIND && (
              <>
                <LevelAlert
                  questionnaireLevelInfo={questionnaireLevelInfo}
                  refresh={refresh}
                  refreshQuestionnaireLevel={refreshQuestionnaireLevel}
                />
                <Box py={2}>
                  <ComplianceLevelBox
                    companyId={company?.id}
                    questionnaireLevelInfo={questionnaireLevelInfo}
                    refresh={refresh}
                  />
                </Box>
              </>
            )}
            <Grid container spacing={2}>
              {response?.data?.questionnaires?.map((questionnaire) => (
                <QuestionnaireCard
                  key={questionnaire.id}
                  questionnaire={questionnaire}
                />
              ))}
            </Grid>
          </>
        )}
      </Container>
      <AdequacyModal
        company={company}
        open={openModal}
        handleClose={handleClose}
        nonCompliance={nonCompliance}
        setOpenModal={setOpenModal}
      />
    </Page>
  )
}

export default QuestionnairesMain
