import {
  AlertOctagon as AlertOctagonIcon,
  BarChart2 as BarChartIcon,
  Briefcase as BriefcaseIcon,
  Clipboard as ClipboardIcon,
  File as FileIcon,
  Home as HomeIcon,
  Layers as LayersIcon,
  Play as PlayIcon,
  Shield as ShieldIcon,
  Package as PackageIcon,
  ShoppingCart as ShoppingCartIcon,
  Archive as ArchiveIcon,
  Feather as FeatherIcon,
  ThumbsUp as ThumbsUpIcon,
} from 'react-feather'

import { SVG } from 'components'

import { routes } from 'Routes'

import * as service from 'service'
import constants from 'constants/index'

const permission = constants.permissions

const mountMenuItems = (countBadgeResponse, isSupplier = false, user) => {
  const suppliersStep = user?.userSteps?.find(
    (userStep) => userStep?.tag === constants.userSteps?.STEP_SUPPLIERS_TAG,
  )

  return [
    {
      items: [
        {
          main: true,
          title: 'Marketplace',
          icon: ShoppingCartIcon,
          href: routes.marketplace.all,
          items: [
            {
              title: 'Início',
              href: routes.marketplace.all,
            },
            {
              title: 'Produtos e Serviços',
              href: routes.marketplace.products,
            },
            {
              title: 'Meus pedidos',
              href: routes.marketplace.orders,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Home',
          icon: HomeIcon,
          href: routes.myLgpd.home,
        },
      ],
    },
    {
      somePermissions: permission.MY_LGPD.SHOW,
      items: [
        {
          main: true,
          title: 'Dashboard',
          icon: BarChartIcon,
          href: routes.myLgpd.dashboard,
          somePermissions: permission.MY_LGPD.SHOW,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Treinamentos',
          icon: PlayIcon,
          href: '?redirectToMoodle=true',
          alwaysUnlocked: true,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Processos',
          icon: LayersIcon,
          somePermissions: [
            permission.DATA_PROCESSES.LIST,
            permission.DATA_PROCESSES.LIST_AND_MANAGE_SUGGESTED,
            permission.DATA_PROCESSES.RESUME,
          ],
          href: routes.dataProcess.all,
          items: [
            {
              title: 'Resumo',
              href: routes.dataProcess.resume,
              somePermissions: [
                permission.DATA_PROCESSES.LIST,
                permission.DATA_PROCESSES.RESUME,
              ],
            },
            {
              title: 'Todos os processos',
              href: routes.dataProcess.all,
              permission: permission.DATA_PROCESSES.LIST,
            },
            {
              title: 'Novos sugeridos',
              href: routes.dataProcess.suggested,
              countToBadge: countBadgeResponse,
              permission: permission.DATA_PROCESSES.LIST_AND_MANAGE_SUGGESTED,
            },
            {
              title: 'Alterações sugeridas',
              href: routes.dataProcess.suggestedChanges,
              permission: constants.permissions.DATA_PROCESSES.CREATE,
            },
            {
              title: 'Auditoria',
              href: routes.audits.all,
              permission: constants.permissions.DATA_PROCESSES.LIST,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Diagnóstico',
          icon: FileIcon,
          href: routes.questionnaire.all,
          unlockToSupplier: true,
          somePermissions: [
            constants.permissions.QUESTIONNAIRES.REPLY,
            constants.permissions.DIAGNOSTICS.LIST,
            permission.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES,
          ],
          items: [
            {
              title: 'Questionários',
              href: routes.questionnaire.all,
              unlockToSupplier: true,
              permission: constants.permissions.QUESTIONNAIRES.REPLY,
            },
            {
              title: 'Medidas de segurança',
              showBlocked: isSupplier,
              href: routes.questionControls.all,
              permission: constants.permissions.DIAGNOSTICS.LIST,
            },
            {
              title: 'Ameaças',
              href: routes.companyFragilities.all,
              permission:
                permission.COMPANY_FRAGILITIES.LIST_COMPANY_FRAGILITIES,
            },
          ],
        },
      ],
    },
    {
      somePermissions: [permission.INCIDENTS.LIST],
      items: [
        {
          main: true,
          title: 'Incidentes',
          icon: AlertOctagonIcon,
          permission: permission.INCIDENTS.LIST,
          href: routes.incidents.all,
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Relatórios',
          icon: ClipboardIcon,
          href: routes.reports.all,
          somePermissions: [permission.RIPD.EXPORT],
          items: [
            {
              title: 'Relatório Gerencial',
              href: routes.reports.management,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Impacto',
              href: routes.reports.ripd,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'RoPA',
              href: routes.reports.ropa,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Prestação de contas',
              href: routes.reports.accountability,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Medidas de Segurança',
              href: routes.reports.control,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Ameaças',
              href: routes.reports.fragility,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Treinamento',
              href: routes.reports.training,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Legítimo Interesse',
              href: routes.reports.lia,
              permission: permission.RIPD.EXPORT,
            },
            {
              title: 'Relatório de Consentimentos',
              href: routes.reports.consent,
              permission: permission.RIPD.EXPORT,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Canal de Comunicação',
          icon: ShieldIcon,
          alwaysUnlocked: true,
          href: routes.privacyPolicy.all,
          items: [
            {
              title: 'Solicitações de titulares',
              href: routes.titularsSolicitations,
              alwaysUnlocked: true,
              somePermissions: [
                permission.PRIVACY_POLICIES.LIST,
                permission.PRIVACY_POLICIES.STAMP,
              ],
            },
            {
              title: 'Termos e Políticas',
              href: routes.privacyPolicy.all,
              somePermissions: [
                permission.PRIVACY_POLICIES.LIST,
                permission.PRIVACY_POLICIES.STAMP,
              ],
              alwaysUnlocked: true,
            },
            {
              title: 'Meios de Contato',
              href: routes.privacyStamp,
              somePermissions: [
                permission.PRIVACY_POLICIES.LIST,
                permission.PRIVACY_POLICIES.STAMP,
              ],
              alwaysUnlocked: true,
            },
            {
              title: 'Canal de Denúncia',
              href: routes.complaint.all,
              alwaysUnlocked: true,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Fornecedores',
          icon: PackageIcon,
          alwaysUnlocked: !isSupplier && suppliersStep?.concluded,
          href: routes.mySuppliers.all,
          somePermissions: [
            permission.COMPANY_SUPPLIERS.SHOW,
            permission.COMPANY_SUPPLIERS.UPDATE,
          ],
          items: [
            {
              showBlocked: isSupplier,
              title: 'Meus fornecedores',
              permission: permission.COMPANY_SUPPLIERS.LIST_IAM_CLIENT,
              href: routes.mySuppliers.all,
            },
            {
              title: 'Sou fornecedor',
              permission: permission.COMPANY_SUPPLIERS.LIST_IAM_SUPPLIER,
              href: routes.supplier.all,
            },
            {
              title: 'Sugestão de fornecedores',
              somePermissions: [
                permission.SUPPLIER_SUGGESTIONS.LIST,
                permission.SUPPLIER_SUGGESTIONS.MANAGE,
              ],
              href: routes.supplierSuggestions.all,
            },
            {
              showBlocked: isSupplier,
              title: 'Convite de fornecedores',
              somePermissions: [
                permission.COMPANY_SUPPLIERS.SHOW,
                permission.COMPANY_SUPPLIERS.UPDATE,
              ],
              href: routes.supplierInvites.all,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Consentimentos',
          icon: ThumbsUpIcon,
          alwaysUnlocked: true,
          somePermissions: [permission.CONSENTS.LIST],
          href: routes.consent.all,
          items: [
            {
              title: 'Formulários',
              href: routes.consent.all,
              permission: permission.CONSENTS.LIST,
            },
            {
              title: 'Recibos',
              href: routes.consent.consentReceipt,
              permission: permission.CONSENT_RECEIPTS.LIST,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Empresa',
          icon: BriefcaseIcon,
          alwaysUnlocked: true,
          somePermissions: [
            permission.DEPARTMENTS.LIST,
            permission.USERS.LIST,
            permission.PROFILES.LIST,
          ],
          href: isSupplier ? routes.supplier.all : routes.user.all,
          items: [
            {
              title: 'Departamentos',
              href: routes.department.all,
              permission: permission.DEPARTMENTS.LIST,
            },
            {
              title: 'Usuários',
              href: routes.user.all,
              permission: permission.USERS.LIST,
              alwaysUnlocked: true,
            },
            {
              title: 'Perfis',
              permission: constants.permissions.PROFILES.LIST,
              href: routes.profiles.all,
              alwaysUnlocked: true,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Documentos',
          icon: ArchiveIcon,
          alwaysUnlocked: true,
          href: routes.documents,
          somePermissions: [
            permission.DOCUMENT.LIST,
            permission.DOCUMENTS_TEMPLATE.LIST,
          ],
          items: [
            {
              title: 'Meus documentos',
              href: routes.documents,
              permission: permission.DOCUMENT.LIST,
            },
            {
              title: 'Modelos',
              permission: permission.DOCUMENTS_TEMPLATE.LIST,
              href: routes.documentsTemplate,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          main: true,
          title: 'Cookies',
          show: service.cookies.auth.getToken() ? true : false, // necessário implementar permissões na api de cookies
          icon: SVG.CookieIcon,
          alwaysUnlocked: true,
          href: routes.cookies.banners,
          items: [
            {
              title: 'Meus banners',
              href: routes.cookies.banners,
            },
            {
              title: 'Consentimentos',
              href: routes.cookies.consents,
            },
          ],
        },
      ],
    },
    {
      items: [
        {
          title: 'Central da Privacidade',
          icon: FeatherIcon,
          alwaysUnlocked: true,
          href: routes.technicalFaqs.lgpdMain,
          items: [
            {
              title: 'LGPD',
              href: routes.technicalFaqs.lgpdMain,
              alwaysUnlocked: true,
            },
            {
              title: 'Segurança da Informação',
              href: routes.technicalFaqs.informationSecurityMain,
              alwaysUnlocked: true,
            },
            {
              title: 'Solicitações',
              href: routes.technicalFaqs.tickets,
              alwaysUnlocked: true,
            },
          ],
        },
      ],
    },
  ]
}

export default mountMenuItems
