import { makeStyles } from '@material-ui/core'

const styles = ({
  attachmentsBgcolor,
  backgroundColor,
  color,
  isDragging,
  width,
}) =>
  makeStyles((theme) => ({
    formControl: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      cursor: 'pointer',
      minHeight: 150,
      gap: theme.spacing(1),
      padding: theme.spacing(2),
      border: `1px dashed ${color}`,
      color: color,
      backgroundColor: backgroundColor,
      width: width,
    },
    title: {
      fontWeight: isDragging ? 500 : 400,
    },
    input: {
      display: 'none',
    },
    controlBar: {
      paddingInline: theme.spacing(2),
      paddingTop: theme.spacing(2),
      border: `1px dashed ${theme.palette.custom.dark}`,
      color: theme.palette.custom.dark,
      marginTop: theme.spacing(2),
      backgroundColor: backgroundColor,
    },
    attachmentItem: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      padding: theme.spacing(1),
      borderRadius: 4,
      backgroundColor: attachmentsBgcolor,
      cursor: 'pointer',
      width: 'fit-content',
    },
  }))

export default styles
