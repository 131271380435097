import { isNil, isEmpty } from 'lodash'

import constants from 'constants/index'

const size = (unit, sizeLimit, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  const exponentiation = constants.validations.BYTE_UNIT_EXPONENT[unit]
  if (files instanceof FileList)
    return Array.from(files).every(
      (file) => file?.size <= sizeLimit * exponentiation,
    )
  else return files?.size <= sizeLimit * exponentiation
}

const type = (permittedTypes, files) => {
  if (isNil(files) || (files instanceof FileList && isEmpty(files))) return true

  if (files instanceof FileList)
    return Array.from(files).every((file) =>
      permittedTypes.includes(file?.type),
    )
  else return permittedTypes.includes(files?.type)
}

const uniqueFile = (value) => {
  if (!value || value.length === 0) return true

  const fileNames = Array.from(value)?.map((file) => file.name)
  const hasDuplicates = fileNames.some(
    (name, idx) => fileNames.indexOf(name) !== idx,
  )

  return !hasDuplicates
}

const isSameFile = (file1, file2) => {
  return file1?.name === file2?.name && file1?.size === file2?.size
}

const isFileInList = (file, fileList) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.some((apiFile) => isSameFile(file, apiFile))
  )
}

const filterExistingFiles = (fileList, apiFiles) => {
  const filesArray = fileList ? Array.from(fileList) : []

  return (
    Array.isArray(filesArray) &&
    filesArray?.filter((file) => !isFileInList(file, apiFiles))
  )
}

const arrayToFileList = (files) => {
  const dataTransfer = new DataTransfer()
  if (isEmpty(files)) return dataTransfer

  files.forEach((file) => dataTransfer.items.add(file))

  return dataTransfer.files
}

const findFileIdByName = (filesRemovedArray, apiFiles) => {
  console.log(apiFiles)
  if (isEmpty(filesRemovedArray) || isEmpty(apiFiles)) return []

  return filesRemovedArray?.flatMap((file) =>
    apiFiles
      .filter(
        (apiFile) =>
          apiFile.name === file.name || apiFile.url.includes(file.name),
      )
      .map((apiFile) => apiFile.id),
  )
}

export default {
  size,
  type,
  uniqueFile,
  isSameFile,
  filterExistingFiles,
  isFileInList,
  arrayToFileList,
  findFileIdByName,
}
