const MAX_FILE_SIZE = 10 * 1024 * 1024
const FILE_TYPES_IMAGE = '.jpg,.gif,.png,.jpeg,.svg,.webp'
const FILE_TYPES_DOCS =
  '.doc,.docx,.ppt,.pptx,.txt,.pdf,.xls,.xlsx,.csv,.ods,.odt,.rtf'

const ALL_FILE_TYPES = `${FILE_TYPES_IMAGE},${FILE_TYPES_DOCS}`

const SUPPORTED_IMAGE_FILE_TYPES = [
  'image/jpeg', // .jpg, .jpeg
  'image/gif', // .gif
  'image/png', // .png
  'image/svg+xml', // .svg
  'image/webp', // .webp
]

const SUPPORTED_DOCUMENT_FILE_TYPES = [
  'application/msword', // .doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // .docx
  'application/vnd.ms-powerpoint', // .ppt
  'application/vnd.openxmlformats-officedocument.presentationml.presentation', // .pptx
  'text/plain', // .txt
  'application/pdf', // .pdf
  'application/vnd.ms-excel', // .xls
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // .xlsx
  'text/csv', // .csv
  'application/vnd.oasis.opendocument.spreadsheet', // .ods
  'application/vnd.oasis.opendocument.text', // .odt
  'application/rtf', // .rtf
]

const BYTES_UNIT = 'bytes'
const KILOBYTE_UNIT = 'kB'
const MEGABYTE_UNIT = 'MB'
const GIGABYTE_UNIT = 'GB'

const BYTE_UNIT_EXPONENT = {
  [BYTES_UNIT]: 2 ** 1,
  [KILOBYTE_UNIT]: 2 ** 10,
  [MEGABYTE_UNIT]: 2 ** 20,
  [GIGABYTE_UNIT]: 2 ** 30,
}
export default {
  MAX_FILE_SIZE,
  FILE_TYPES_IMAGE,
  FILE_TYPES_DOCS,
  BYTE_UNIT_EXPONENT,
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_DOCUMENT_FILE_TYPES,
  ALL_FILE_TYPES,
}
