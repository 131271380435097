import { makeStyles } from '@material-ui/core'

const styles = makeStyles((theme) => ({
  card: {
    color: theme.palette.white,
    fontWeight: 700,
    margin: theme.spacing(0.5),
    flexGrow: 1,
    maxWidth: 500,
    minWidth: 115,
    cursor: 'pointer',
    boxShadow: '3px 3px 6px rgba(0, 0, 0, 0.16)',
    transition: theme.transitions.create('flex-grow', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandedCard: {
    flexGrow: 2,
  },
  details: {
    maxWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  detailsOpen: {
    maxWidth: '100%',
  },
  levelOneCard: {
    backgroundColor: theme.palette.primary.main,
  },
  levelTwoCard: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.primary.main} 50%, ${theme.palette.primary.hover} 100%)`,
  },
  levelThreeCard: {
    backgroundColor: theme.palette.primary.hover,
  },
  accordionLevel: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    '&.Mui-expanded': {
      margin: 0,
    },
  },
  disabled: {
    opacity: 0.8,
    pointerEvents: 'none',
  },
  contextText: {
    height: 150,
    marginTop: theme.spacing(1),
    overflowY: 'auto',
  },
  boxIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    backgroundColor: 'white',
    width: 24,
    height: 24,
    padding: 2,
    marginRight: theme.spacing(1),
  },
}))

export default styles
