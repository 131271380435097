import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Box, Button, Grid } from '@material-ui/core'

import {
  AvaliationStep,
  CommunicationStep,
  DetalingStep,
  DetectionStep,
  InvestigationStep,
  LessonsLearnedStep,
  Stepper,
  TreatmentStep,
} from './components'

import useIncidentManegement from 'hooks/useIncidentManegement'
import useIncident from 'hooks/useIncident'

import theme from 'theme'
import incidentManegementSteps from 'helpers/incidents/incidentManegementSteps'
import RmcProvider from 'providers/RmcProvider'

const Form = ({ type, refresh = () => {}, query }) => {
  const {
    handleBack,
    activeStep,
    showButtons,
    activeButton,
    handleAdvanceStep,
    incident,
    wantsToReturnStep,
    handleReturnStep,
    resetReturnStep,
  } = useIncidentManegement()
  const {
    activeStep: completeFormActiveStep,
    backStep: handleBackCompleteFormStep,
  } = useIncident()

  const steps = incidentManegementSteps(incident, type)
  const step = steps[activeStep]

  const stepComponents = {
    detection: <DetectionStep query={query} />,
    investigation: <InvestigationStep />,
    avaliation: <AvaliationStep type={type} />,
    detaling: <DetalingStep refresh={refresh} />,
    treatment: (
      <RmcProvider>
        <TreatmentStep refresh={refresh} />
      </RmcProvider>
    ),
    communication: <CommunicationStep />,
    lessons_learned: <LessonsLearnedStep />,
  }

  const isCreate = type === 'create'

  const actionButtonText = () => {
    if ((isCreate && step === 'avaliation') || step === 'lessons_learned') {
      return 'Finalizar'
    }

    return 'Avançar'
  }

  useEffect(() => {
    if (wantsToReturnStep) {
      if (step === 'detaling') {
        if (completeFormActiveStep === 0) {
          handleBack()
        } else {
          refresh()
          handleBackCompleteFormStep()
        }
      } else {
        handleBack()
      }

      resetReturnStep()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wantsToReturnStep])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Stepper type={type} />
      </Grid>

      <Grid item xs={12}>
        <Box mt={3} />

        {stepComponents[step]}
      </Grid>

      {showButtons && (
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-end"
            gridGap={theme.spacing(2)}
          >
            <Button variant="outlined" onClick={handleReturnStep}>
              Voltar
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAdvanceStep}
              disabled={!activeButton}
            >
              {actionButtonText()}
            </Button>
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

Form.propTypes = {
  type: PropTypes.string,
  refresh: PropTypes.func,
  query: PropTypes.object,
}

export default Form
