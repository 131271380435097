import { size } from 'lodash'
import { Editor, Transforms } from 'slate'

import constants from 'constants/index'

const isElementWithType = (node) => 'type' in node

const toolbarTitleLabel = (format) => {
  return constants.slateJs.TOOLBAR_BUTTONS_LABEL[format]
}

const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor)
  return marks ? marks[format] === true : false
}

const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format)

  if (isActive) Editor.removeMark(editor, format)
  else Editor.addMark(editor, format, true)
}

const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => isElementWithType(n) && n.type === format,
  })

  return !!match
}

const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format)
  const isList = format === 'ordered-list' || format === 'unordered-list'

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      isElementWithType(n) &&
      (n.type === 'ordered-list' || n.type === 'unordered-list'),
    split: true,
  })

  const newProperties = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  }

  Transforms.setNodes(editor, newProperties)

  if (!isActive && isList) {
    const block = { type: format, children: [] }
    Transforms.wrapNodes(editor, block)
  }
}

const hasContent = (html) => size(html.replace(/<[^>]*>/g, '').trim()) > 0

const addBrToEmptyTags = (html) =>
  html.replace(/<([a-z][a-z0-9]*)\b[^>]*>(\s*)<\/\1>/gi, '<$1><br></$1>')

// Função para serializar o conteúdo do editor em HTML
const serialize = (nodes) => {
  return nodes.map((node) => serializeNode(node)).join('')
}

// Função para desserializar o conteúdo HTML para o formato do Slate
const deserialize = (elements) => {
  return elements.map((element) => deserializeElement(element))
}

const serializeNode = (node) => {
  if (node.text) {
    let string = node.text

    if (node.bold) string = `<strong>${string}</strong>`
    if (node.italic) string = `<em>${string}</em>`
    if (node.underline) string = `<u>${string}</u>`
    if (node.strikethrough) string = `<del>${string}</del>`
    if (node.superscript) string = `<sup>${string}</sup>`
    if (node.subscript) string = `<sub>${string}</sub>`

    return string
  }

  const children = node?.children?.map((n) => serializeNode(n)).join('')

  switch (node.type) {
    case 'paragraph':
      return `<p>${children}</p>`
    case 'heading-one':
      return `<h1>${children}</h1>`
    case 'heading-two':
      return `<h2>${children}</h2>`
    case 'heading-three':
      return `<h3>${children}</h3>`
    case 'blockquote':
      return `<blockquote>${children}</blockquote>`
    case 'ordered-list':
      return `<ol>${children}</ol>`
    case 'unordered-list':
      return `<ul>${children}</ul>`
    case 'list-item':
      return `<li>${children}</li>`
    case 'align-left':
      return `<div style="text-align: left;">${children}</div>`
    case 'align-center':
      return `<div style="text-align: center;">${children}</div>`
    case 'align-right':
      return `<div style="text-align: right;">${children}</div>`
    default:
      return children
  }
}

const deserializeElement = (element) => {
  const nodeName = element.nodeName.toLowerCase()

  let children = [{ text: element.textContent || '' }]

  if (element.childNodes.length > 0)
    children = deserialize(Array.from(element.childNodes))

  switch (nodeName) {
    case 'strong':
      return { ...children[0], bold: true }
    case 'em':
      return { ...children[0], italic: true }
    case 'u':
      return { ...children[0], underline: true }
    case 'del':
      return { ...children[0], strikethrough: true }
    case 'sup':
      return { ...children[0], superscript: true }
    case 'sub':
      return { ...children[0], subscript: true }
    case 'p':
      return { type: 'paragraph', children }
    case 'h1':
      return { type: 'heading-one', children }
    case 'h2':
      return { type: 'heading-two', children }
    case 'h3':
      return { type: 'heading-three', children }
    case 'blockquote':
      return { type: 'blockquote', children }
    case 'ol':
      return { type: 'ordered-list', children }
    case 'ul':
      return { type: 'unordered-list', children }
    case 'li':
      return { type: 'list-item', children }
    case 'div':
      if (element.style.textAlign === 'left')
        return { type: 'align-left', children }
      if (element.style.textAlign === 'center')
        return { type: 'align-center', children }
      if (element.style.textAlign === 'right')
        return { type: 'align-right', children }
      return children[0]
    default:
      return children[0]
  }
}

const onKeyDown = (event, editor) => {
  const { ctrlKey, key } = event

  if (ctrlKey) {
    switch (key) {
      case 'b':
        event.preventDefault()
        toggleMark(editor, 'bold')
        break
      case 'i':
        event.preventDefault()
        toggleMark(editor, 'italic')
        break
      case 'u':
        event.preventDefault()
        toggleMark(editor, 'underline')
        break
      case 's':
        event.preventDefault()
        toggleMark(editor, 'strikethrough')
        break
      case '1':
        event.preventDefault()
        toggleBlock(editor, 'heading-one')
        break
      case '2':
        event.preventDefault()
        toggleBlock(editor, 'heading-two')
        break
      case '3':
        event.preventDefault()
        toggleBlock(editor, 'heading-three')
        break
      case 'q':
        event.preventDefault()
        toggleBlock(editor, 'blockquote')
        break
      case 'l':
        event.preventDefault()
        toggleBlock(editor, 'ordered-list')
        break
      case 'e':
        event.preventDefault()
        toggleMark(editor, 'superscript')
        break
      case 'r':
        event.preventDefault()
        toggleMark(editor, 'subscript')
        break
      case 'ArrowLeft':
        event.preventDefault()
        toggleBlock(editor, 'align-left')
        break
      case 'ArrowRight':
        event.preventDefault()
        toggleBlock(editor, 'align-right')
        break
      case 'ArrowUp':
        event.preventDefault()
        toggleBlock(editor, 'align-center')
        break
      case 'o':
        event.preventDefault()
        toggleBlock(editor, 'unordered-list')
        break
      default:
        break
    }
  }
}

export default {
  addBrToEmptyTags,
  deserialize,
  hasContent,
  isBlockActive,
  isMarkActive,
  onKeyDown,
  serialize,
  toggleBlock,
  toggleMark,
  toolbarTitleLabel,
}
