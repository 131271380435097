import React from 'react'

import { Grid, Box, TextField, InputAdornment } from '@material-ui/core'
import { Controller } from 'react-hook-form'
import { Label } from 'components'

import brlIcon from 'images/brazilian-real-sign-solid.svg'

import helpers from 'helpers'

const Asking = ({ control, errors, isDisabled }) => {
  return (
    <Grid item xs={12}>
      <Box m={(2, 2, 0, 2)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Label
              title="O que"
              description="Ações concretas e objetivas que serão tomadas"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.whatQuestion}
                    helperText={errors?.whatQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="whatQuestion"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label
              title="Como"
              description="Planejamento para execução da ação"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.howQuestion}
                    helperText={errors?.howQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="howQuestion"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label
              title="Porquê"
              description="A meta que se objetiva com a ação"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.becauseQuestion}
                    helperText={errors?.becauseQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="becauseQuestion"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label
              title="Onde"
              description="Em qual setor a ação será realizada"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.whereQuestion}
                    helperText={errors?.whereQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="whereQuestion"
                mode="onBlur"
              />
            </Label>
          </Grid>
          <Grid item xs={6}>
            <Label
              title="Quanto"
              description="Impacto financeiro da ação"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.howPriceQuestion}
                    helperText={errors?.howPriceQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={brlIcon} alt="Logo" width={15} />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
                control={control}
                name="howPriceQuestion"
                mode="onChange"
                onChange={([event]) => {
                  return helpers.formatters.currencyInput(
                    event.target.value || '0',
                  )
                }}
              />
            </Label>
          </Grid>
          <Grid item xs={12}>
            <Label
              title="Link"
              description="Upload de documento e/ou link"
              marginTopChildren={0}
            >
              <Controller
                as={
                  <TextField
                    type="text"
                    color="primary"
                    variant="outlined"
                    error={!!errors.whereQuestion}
                    helperText={errors?.whereQuestion?.message}
                    fullWidth
                    disabled={isDisabled}
                  />
                }
                control={control}
                name="link"
                mode="onBlur"
              />
            </Label>
          </Grid>
        </Grid>
      </Box>
    </Grid>
  )
}

export default Asking
