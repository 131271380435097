import audits from './audits'
import cardFlags from './cardFlags'
import consents from './consents'
import file from './file'
import lodash from './lodash'
import settings from './settings'
import slateJs from './slateJs'

export default {
  audits,
  cardFlags,
  consents,
  file,
  lodash,
  settings,
  slateJs,
}
