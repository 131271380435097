import * as yup from 'yup'

import helpers from 'helpers'

import constants from 'constants/index'
import { isEmpty } from 'lodash'

const {
  FILE_TYPES_IMAGE,
  FILE_TYPES_DOCS,
  SUPPORTED_IMAGE_FILE_TYPES,
  SUPPORTED_DOCUMENT_FILE_TYPES,
} = constants.validations

const allowedTypes = FILE_TYPES_IMAGE + FILE_TYPES_DOCS
const allowedMIMESTypes =
  SUPPORTED_IMAGE_FILE_TYPES + SUPPORTED_DOCUMENT_FILE_TYPES

const schema = yup.object().shape({
  content: yup
    .string()
    .max(5000)
    .when('attachments', {
      is: (attachments) => !isEmpty(attachments),
      then: (schema) => schema.required(),
    }),
  complaintStatus: yup.string().required(),
  attachments: yup
    .mixed()
    .test('fileSize', 'A arquivo deve ter no máximo 5 MB', (value) =>
      helpers.validates.file.size('MB', 5, value?.size),
    )
    .test(
      'fileType',
      `Somente arquivos ${helpers.formatters.file.allowedTypes(
        allowedTypes,
      )} são permitidos`,
      (value) => helpers.validates.file.type(allowedMIMESTypes, value?.type),
    ),
})

export default schema
