import { useEffect, useState } from 'react'

const useConvertToFile = (attachments) => {
  const [loading, setLoading] = useState(true)
  const [fileList, setFileList] = useState(null)

  useEffect(() => {
    const convertToFile = async (attachment) => {
      const response = await fetch(attachment.url)
      const blob = await response.blob()
      return new File([blob], attachment.name, { type: blob.type })
    }

    const convertAll = async () => {
      setLoading(true)
      const filePromises = attachments?.map((attachment) =>
        convertToFile(attachment),
      )
      const convertedFiles = await Promise.all(filePromises)

      const dataTransfer = new DataTransfer()
      convertedFiles.forEach((file) => dataTransfer.items.add(file))

      setFileList(dataTransfer.files)
      setLoading(false)
    }

    if (attachments?.length) {
      convertAll()
    } else {
      setLoading(false)
    }
  }, [attachments])

  return { fileList, loading }
}

export default useConvertToFile
